import type { Color } from '@onefootprint/design-tokens';
import type { Icon } from '@onefootprint/icons';
import { type VariantProps, cva } from 'class-variance-authority';
import { cx } from 'class-variance-authority';
import { forwardRef } from 'react';

export type IconButtonSize = 'default' | 'large' | 'compact' | 'tiny';
type IconButtonColor = Color;

export type IconButtonProps = {
  'aria-label': string;
  icon: Icon;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  color?: IconButtonColor;
  destructive?: boolean;
} & VariantProps<typeof iconButton>;

const iconButton = cva(['flex items-center justify-center box-border cursor-pointer transition-colors duration-100'], {
  variants: {
    size: {
      large: 'size-10 rounded',
      default: 'size-8 rounded',
      compact: 'size-7 rounded',
      tiny: 'size-6 rounded-sm',
    },
    variant: {
      outline: [
        'border border-solid border-tertiary bg-primary shadow-[0px_1px_2px_0px_rgba(0,0,0,0.12)] dark:shadow-[0px_1px_2px_0px_rgba(0,0,0,0.24)]',
        'hover:bg-secondary hover:border-primary hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.12)] dark:hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.2)]',
        'active:bg-secondary active:border-primary active:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.12)] dark:active:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.24)]',
        'disabled:pointer-events-none disabled:bg-secondary disabled:border-tertiary disabled:shadow-[0px_1px_2px_0px_rgba(0,0,0,0.12)] dark:disabled:shadow-none',
      ],
      ghost: [
        'bg-transparent border-none',
        'hover:bg-secondary active:bg-secondary',
        'disabled:cursor-default disabled:opacity-50',
        'data-[destructive=true]:hover:bg-error data-[destructive=true]:active:bg-error',
      ],
    },
    destructive: {
      true: [
        'hover:bg-error active:bg-error',
        'data-[destructive=true]:border-error data-[destructive=true]:hover:border-error',
      ],
    },
  },
  defaultVariants: {
    size: 'default',
    variant: 'ghost',
  },
});

const getIconColor = (color?: IconButtonColor, destructive?: boolean, disabled?: boolean) => {
  if (destructive) {
    return 'error';
  }
  if (disabled) {
    return 'quaternary';
  }
  if (color) {
    return color;
  }
  return 'primary';
};

const handleClick = (
  event: React.MouseEvent<HTMLButtonElement>,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
) => {
  event.stopPropagation();
  onClick?.(event);
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      'aria-label': ariaLabel,
      icon: Icon,
      onClick,
      size,
      variant,
      disabled,
      className,
      color = 'primary',
      destructive,
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    return (
      <button
        className={cx(iconButton({ size, variant, destructive }), className)}
        aria-label={ariaLabel}
        onClick={e => handleClick(e, onClick)}
        disabled={disabled}
        ref={ref}
        tabIndex={0}
        type="button"
        data-destructive={destructive}
        {...props}
      >
        <Icon color={getIconColor(color, destructive, disabled)} />
      </button>
    );
  },
);

export default IconButton;
