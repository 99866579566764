import type {
  VaultDocumentData,
  VaultEmptyData,
  VaultEncryptedData,
  VaultImageData,
  VaultTextData,
} from '@onefootprint/core';
import type { BusinessDI, DocumentDI, IdDI, InvestorProfileDI } from './di';

export enum DataKind {
  documentData = 'document_data',
  vaultData = 'vault_data',
}

export type VaultInvestorProfile = Partial<
  Record<InvestorProfileDI, VaultTextData | VaultEncryptedData | VaultEmptyData>
>;

export type VaultBusiness = Partial<Record<BusinessDI, VaultTextData | VaultEncryptedData | VaultEmptyData>>;
export type VaultId = Partial<Record<IdDI, VaultTextData | VaultEncryptedData | VaultEmptyData>>;

export type VaultDocument = Partial<Record<DocumentDI, VaultDocumentData | VaultEncryptedData | VaultEmptyData>>;

export type VaultImage = Partial<Record<DocumentDI, VaultImageData | VaultEncryptedData | VaultEmptyData>>;
